import { Cards, ContainerSize, Link } from '@lib/types';
import classNames from 'classnames';
import Container from '@components/container';
import NextLink from 'next/link';
import { ArrowIcon } from '@components/icons';
import { ReactNode } from 'react';
import styles from './cards.module.css';

type CardsSectionProps = {
  data: Cards;
};

type CardProps = {
  children: ReactNode;
  link: Link | null;
};

function Card({ children, link }: CardProps) {
  const className = 'py-4 md:py-0';

  if (link) {
    return (
      <NextLink href={link.url} className={className}>
        {children}
      </NextLink>
    );
  }

  return <div className={className}>{children}</div>;
}

export default function CardsSection({
  data: { elements },
}: CardsSectionProps) {
  return (
    <Container size={ContainerSize.wide}>
      <div className="md:grid md:grid-cols-12 md:gap-6">
        <div
          className={classNames(
            'col-start-1 col-span-12 lg:col-start-3 lg:col-span-8 grid grid-cols-1 md:grid-cols-2 md:gap-6 pointer-events-none',
            {
              [styles.cards]:
                elements.length !== 2 && elements.some((e) => e.link),
            },
          )}>
          {elements.map(({ overline, title, html, link }, index) => (
            <Card key={index} link={link}>
              <div
                className={classNames(
                  'flex flex-col text-white px-6 py-12 rounded-xl md:rounded-3xl h-full group transition duration-300 pointer-events-auto',
                  {
                    'bg-blue-500': elements.length !== 2 || index !== 1,
                    'hover:bg-blue-800 hover:scale-105':
                      (elements.length !== 2 || index !== 1) && link,
                    'bg-iceblue-500': elements.length === 2 && index === 1,
                    'hover:bg-[#4DA7B5] hover:scale-105':
                      elements.length === 2 && index === 1 && link,
                    'md:relative md:top-12': index % 2 === 1,
                  },
                )}
                key={index}>
                <h2
                  className={classNames(
                    'font-bold text-17 leading-140 md:text-20',
                    {
                      'text-iceblue-500': elements.length !== 2 || index !== 1,
                      'text-iceblue-800': elements.length === 2 && index === 1,
                    },
                  )}>
                  {overline}
                </h2>
                <h1 className="text-white font-bold text-28 leading-125 md:text-35 leading-130 my-8">
                  {title}
                </h1>
                <div
                  className="flex-1 prose"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                {link && (
                  <span
                    className={classNames(
                      'uppercase font-bold text-white text-13 leading-140 tracking-5 inline-flex items-center mt-24',
                    )}>
                    {link.text}{' '}
                    <ArrowIcon className="w-12 ml-4 transition-transform duration-500 group-hover:translate-x-2" />
                  </span>
                )}
              </div>
            </Card>
          ))}
        </div>
      </div>
    </Container>
  );
}
